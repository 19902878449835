
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
}
@import url("https://use.typekit.net/owc5ubd.css");
// Ironstrike Regular

// font-family: ironstrike, sans-serif;

// font-weight: 400;

// font-style: normal;


// Ironstrike Regular Italic

// font-family: ironstrike, sans-serif;

// font-weight: 400;

// font-style: italic;


// Ironstrike Bold

// font-family: ironstrike, sans-serif;

// font-weight: 700;

// font-style: normal;


// Ironstrike Bold Italic

// font-family: ironstrike, sans-serif;

// font-weight: 700;

// font-style: italic;


// Ironstrike Stencil Regular

// font-family: ironstrike-stencil, sans-serif;

// font-weight: 400;

// font-style: normal;


// Ironstrike Stencil Regular Italic

// font-family: ironstrike-stencil, sans-serif;

// font-weight: 400;

// font-style: italic;


// Ironstrike Stencil Bold

// font-family: ironstrike-stencil, sans-serif;

// font-weight: 700;

// font-style: normal;


// Ironstrike Stencil Bold Italic

// font-family: ironstrike-stencil, sans-serif;

// font-weight: 700;

// font-style: italic;

$backgound-color: #000;
$breakpoints: ('small': 768px, 'large': 1200px);
@mixin responsive($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
        // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}
$primary-color: rgb(73, 73, 73);

body {
  background: $backgound-color;
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4 {
  color: $primary-color;
  text-align: center;
}
p{
  margin: 1rem 0rem;
  color: rgb(167, 167, 167);
}
ol {
  margin: 1rem;
  color: rgb(143, 143, 143);
}
.mainWrapper {
  display: grid;
  align-content: flex-start;
  margin-top: 1rem;
  padding: 0px 4%;
}

@include responsive(large) {
  p, ol {
    font-size: 1.25rem;
  }
}