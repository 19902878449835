@import '../../style.scss';

.sectionThree {
    display: grid;
    align-content: flex-start;
    align-items: flex-start;
    img {
        width: 100%;
    }
    h1 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        text-decoration: underline;
    }
    h2 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        text-align: left;
        font-style: italic;
        color: rgba($color: #949494, $alpha: 1.0);
    }
    section {
        margin: 4rem 0rem;
    }
}

@include responsive(large) {
   .sectionThree {
       h1 {
           font-size: 4rem;
       }
       h2 {
           font-size: 2rem;
       }
   } 
}