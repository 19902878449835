@import '../../style.scss';

.sectionTwo {
    display: grid;
    align-content: flex-start;
    align-items: flex-start;
    img {
        width: 100%;
    }
    h1 {
        font-family: ironstrike-stencil, sans-serif;
        font-size: 3rem;
        font-weight: 900;
        font-style: italic;
        text-decoration: underline;
        text-transform: uppercase;
        text-align: center;
    }
    h2 {
        font-family: ironstrike-stencil, sans-serif;
        font-size: 2rem;
        font-weight: 900;
        font-style: italic;
        color: grey;
        text-decoration: underline;
        text-align: center;

    }
    h3 {
        font-family: ironstrike-stencil, sans-serif;
        font-size: 1.5rem;
        font-weight: 900;
        font-style: italic;
        color: grey;
        text-decoration: underline;
        text-align: center;

    }
    h4 {
        font-family: ironstrike-stencil, sans-serif;
        font-size: 1.25rem;
        font-weight: 900;
        font-style: italic;
        color: grey;
        text-align: center;

    }
    section {
        margin: 3rem 0rem;
    }
}
@include responsive(large) {
  .two-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: left;
      justify-items: left;
      column-gap: 5rem;
      .two-columns-two {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 5rem;
          justify-content: left;
          justify-items: left;
          justify-self: left;
          img {
              display: grid;
              justify-self: left;
              width: 100%;
              height: 300px;
              object-fit: contain;
          }
      }
  }
  .sectionTwo {
      margin: 2rem 0rem;
      h1 {
          font-size: 4rem;
          margin-bottom: 2rem;
      }
      h3 {
          text-align: left;
          font-size: 2rem;
          margin: 1rem 0rem;
      }
      img {
          display: grid;
          justify-self: left;
          width: 95%;
      }
      p {
          width: 100%;
      }
      section {
        margin: 0rem;
    }
  }
}