@import url(https://use.typekit.net/owc5ubd.css);
@import url(https://use.typekit.net/owc5ubd.css);
@import url(https://use.typekit.net/owc5ubd.css);
@import url(https://use.typekit.net/owc5ubd.css);
@import url(https://use.typekit.net/owc5ubd.css);
@import url(https://use.typekit.net/owc5ubd.css);
@import url(https://use.typekit.net/owc5ubd.css);
*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}.buyButton{display:grid;justify-self:center;background:green;color:white;height:35px;font-size:1.25rem;justify-content:center;align-content:center;border:none;width:-webkit-max-content;width:-moz-max-content;width:max-content}.sectionOne{display:grid;margin-top:75px}.sectionOne h3{font-family:ironstrike-stencil, sans-serif;font-weight:900;font-style:normal;font-size:2rem}.sectionOne a{display:grid;justify-items:center;justify-self:center;justify-content:center;text-decoration:none;background:#4fd33d;text-align:center;width:-webkit-max-content;width:-moz-max-content;width:max-content;color:white;margin:1rem;padding:5px}.sectionOne button{display:grid;margin:1rem 0rem;justify-self:center;background:#16c92a;border:0px;padding:.25rem .75rem;width:-webkit-max-content;width:-moz-max-content;width:max-content;color:white}.header{display:grid;width:100%;overflow:hidden}.header img{display:grid;width:100%}@media (min-width: 1200px){.header{display:grid;width:100%}.header img{object-fit:cover;height:60vh;object-position:0px -275px}}

*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}.sectionTwo{display:grid;align-content:flex-start;align-items:flex-start}.sectionTwo img{width:100%}.sectionTwo h1{font-family:ironstrike-stencil, sans-serif;font-size:3rem;font-weight:900;font-style:italic;text-decoration:underline;text-transform:uppercase;text-align:center}.sectionTwo h2{font-family:ironstrike-stencil, sans-serif;font-size:2rem;font-weight:900;font-style:italic;color:grey;text-decoration:underline;text-align:center}.sectionTwo h3{font-family:ironstrike-stencil, sans-serif;font-size:1.5rem;font-weight:900;font-style:italic;color:grey;text-decoration:underline;text-align:center}.sectionTwo h4{font-family:ironstrike-stencil, sans-serif;font-size:1.25rem;font-weight:900;font-style:italic;color:grey;text-align:center}.sectionTwo section{margin:3rem 0rem}@media (min-width: 1200px){.two-columns{display:grid;grid-template-columns:1fr 1fr;justify-content:left;justify-items:left;grid-column-gap:5rem;-webkit-column-gap:5rem;column-gap:5rem}.two-columns .two-columns-two{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:5rem;-webkit-column-gap:5rem;column-gap:5rem;justify-content:left;justify-items:left;justify-self:left}.two-columns .two-columns-two img{display:grid;justify-self:left;width:100%;height:300px;object-fit:contain}.sectionTwo{margin:2rem 0rem}.sectionTwo h1{font-size:4rem;margin-bottom:2rem}.sectionTwo h3{text-align:left;font-size:2rem;margin:1rem 0rem}.sectionTwo img{display:grid;justify-self:left;width:95%}.sectionTwo p{width:100%}.sectionTwo section{margin:0rem}}

*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}.sectionThree{display:grid;align-content:flex-start;align-items:flex-start}.sectionThree img{width:100%}.sectionThree h1{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-decoration:underline}.sectionThree h2{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-align:left;font-style:italic;color:#949494}.sectionThree section{margin:4rem 0rem}@media (min-width: 1200px){.sectionThree h1{font-size:4rem}.sectionThree h2{font-size:2rem}}

*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}.sectionFour{display:grid;align-content:flex-start;align-items:flex-start}.sectionFour img{width:100%}.sectionFour h1{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-decoration:underline;color:#cacaca}.sectionFour h2{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-align:left;font-style:italic}.sectionFour h3{color:#b6b6b6;margin:1rem 0rem}.sectionFour section{margin:4rem 0rem}.sectionFour form{width:100%}.sectionFour form label{color:#cacaca;font-size:.9rem}.sectionFour form input{width:100%;margin:.5rem 0rem;border:none;outline:none;height:30px;padding:5px}.sectionFour form button{display:grid;width:100%;align-content:center;margin:.5rem 0rem;border:none;padding:0px 25px;height:30px;background:#16c92a;color:white}.sectionFour form button:hover{cursor:pointer;background-color:#40e653}.sectionFour p.hint{color:#cacaca;font-size:.8rem}@media (min-width: 1200px){.sectionFour{display:grid;justify-self:center;max-width:600px}.sectionFour h1{font-size:4rem}.sectionFour h3{text-align:center}form{display:grid;justify-self:center}}

*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}nav{display:grid;top:0;position:fixed;background:black;width:100%}.mobile-nav{display:grid;grid-template-columns:2fr 1fr;padding:.5rem 4%}.mobile-nav div{display:grid;align-items:center}.mobile-nav div button{text-decoration:none;background:transparent;border:none;color:#aaa}.mobile-nav div button:hover{cursor:pointer;color:white}.mobile-nav div button.buyMe{display:none}.mobile-nav div img{width:200px}.mobile-nav div svg{color:white;font-size:2rem}.mobile-nav div:nth-child(2){display:grid;justify-items:right;justify-content:right}.mobile-dropdown-menu{display:grid;margin:0px;left:0px;position:absolute;background:black;width:100%;overflow:hidden;transition:ease-in-out 250ms}.mobile-dropdown-menu ul{display:grid;position:relative}.mobile-dropdown-menu ul li{margin:1rem 2rem;font-size:1.25rem}.mobile-dropdown-menu.show{transform:translateY(120%);margin:0px;top:0px}.mobile-dropdown-menu.hide{transform:translateY(-300%)}@media (min-width: 1200px){nav{display:grid;align-items:center;height:75px;background:black}.mobile-nav div svg{display:none}.mobile-nav div button{color:grey}.mobile-nav div button.buyMe{display:grid;border:none;padding:.5rem 1rem;background:transparent}.mobile-nav div button.buyMe:hover{color:white}}

footer{display:grid;grid-template-columns:1fr 1fr 1fr;padding:2rem 2%;justify-content:center}footer p{display:grid;justify-content:center;font-size:.75rem}

*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}

*{box-sizing:border-box;margin:0px;padding:0px;font-family:sans-serif}body{background:#000;width:100%;height:100%}h1,h2,h3,h4{color:#494949;text-align:center}p{margin:1rem 0rem;color:#a7a7a7}ol{margin:1rem;color:#8f8f8f}.mainWrapper{display:grid;align-content:flex-start;margin-top:1rem;padding:0px 4%}@media (min-width: 1200px){p,ol{font-size:1.25rem}}.splash-wrapper{display:grid;justify-content:center;justify-items:center;justify-self:center;align-content:center;align-items:center;align-self:center;height:100vh}.splash-wrapper img{width:100%}.splash-wrapper h1{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-align:left;font-style:italic;text-align:center;color:#7c7c7c}.splash-wrapper h2{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-align:left;font-style:italic;text-align:center;color:#636363}@media (min-width: 1200px){.splash-wrapper h1{font-size:2.5rem}.splash-wrapper h2{font-family:ironstrike-stencil, sans-serif;font-weight:900;text-align:left;font-style:italic;text-align:center;color:#636363;font-size:2rem}}

