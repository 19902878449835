footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2rem 2%;
    justify-content: center;
    p {
        display: grid;
        justify-content: center;
        font-size: .75rem;
    }
}