@import '../../style.scss';

.sectionFour {
    display: grid;
    align-content: flex-start;
    align-items: flex-start;
    img {
        width: 100%;
    }
    h1 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        text-decoration: underline;
        color: rgb(202, 202, 202);
    }
    h2 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        text-align: left;
        font-style: italic;
    }
    h3 {
        color: rgb(182, 182, 182);
        margin: 1rem 0rem;
    }
    section {
        margin: 4rem 0rem;
    }
    form {
        width: 100%;
        label {
            color: rgb(202, 202, 202);
            font-size: .9rem;
        }
        input {
            width: 100%;
            margin: .5rem 0rem;
            border: none;
            outline: none;
            height: 30px;
            padding: 5px;
        }
        button {
            display: grid;
            width: 100%;
            align-content: center;
            margin: .5rem 0rem;
            border: none;
            padding: 0px 25px;
            height: 30px;
            background: rgba($color: #16c92a, $alpha: 1.0);
            color: white;
            &:hover {
                cursor: pointer;
                background-color: #40e653;
            }
        }
    }
    p.hint {
        color: rgb(202, 202, 202);
        font-size: .8rem;
    }
}

@include responsive(large) {
    .sectionFour {
        display: grid;
        justify-self: center;
        max-width: 600px;
        h1 {
            font-size: 4rem;
        }
        h3 {
            text-align: center;
        }
    }
    form {
        display: grid;
        justify-self: center;
    } 
 }