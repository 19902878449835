@import '../../style.scss';

.buyButton {
    display: grid;
    justify-self: center;
    background: green;
    color: white;
    height: 35px;
    font-size: 1.25rem;
    justify-content: center;
    align-content: center;
    border: none;
    width: max-content;
}

.sectionOne {
    display: grid;
    margin-top: 75px;
    h3 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        font-style: normal;
        font-size: 2rem;
    }
    a {
        display: grid;
        justify-items: center;
        justify-self: center;
        justify-content: center;
        text-decoration: none;
        background: rgba($color: #4fd33d, $alpha: 1.0);
        text-align: center;
        width: max-content;
        color: white;
        margin: 1rem;
        padding: 5px;
    }
    button {
        display: grid;
        margin: 1rem 0rem;
        justify-self: center;
        background:#16c92a;
        border: 0px;
        padding: .25rem .75rem;
        width: max-content;
        color: white;
    }
}
.header {
    display: grid;
    width: 100%;
    overflow: hidden;
    img {
        display: grid;
        width: 100%;
    }
}

@include responsive(large) {
    .header{
        display: grid;
        width: 100%;
        img {
            object-fit: cover;
            height: 60vh;
            object-position: 0px -275px;
        }
    }
}