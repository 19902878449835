@import '../style.scss';

.splash-wrapper {
    display: grid;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-content: center;
    align-items: center;
    align-self: center;
    height: 100vh;
    img {
        width: 100%;
    }
    h1 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        text-align: left;
        font-style: italic;
        text-align: center;
        color: rgb(124, 124, 124);
    }
    h2 {
        font-family: ironstrike-stencil, sans-serif;
        font-weight: 900;
        text-align: left;
        font-style: italic;
        text-align: center;
        color: rgb(99, 99, 99);
    }
}
@include responsive(large) { 
    .splash-wrapper {
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-family: ironstrike-stencil, sans-serif;
            font-weight: 900;
            text-align: left;
            font-style: italic;
            text-align: center;
            color: rgb(99, 99, 99);
            font-size: 2rem;
        }
    }
}