@import '../style.scss';

nav {
    display: grid;
    top: 0;
    position: fixed;
    background: black;
    width: 100%;
}
.mobile-nav {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: .5rem 4%;
    div {
        display: grid;
        align-items: center;
        button {
            text-decoration: none;
            background: transparent;
            border: none;
            color: rgba($color: rgb(170, 170, 170), $alpha: 1.0);
            &:hover {
                cursor: pointer;
                color: white;
            }
            &.buyMe {
                display: none;
            }

    } 
        img {
            width: 200px;
        }
        svg {
            color: white;
            font-size: 2rem;
        }
        &:nth-child(2) {
            display: grid;
            justify-items: right;
            justify-content: right;
        }
    }
}
.mobile-dropdown-menu {
    display: grid;
    margin: 0px;
    left: 0px;
    position: absolute;
    background: black;
    width: 100%;
    overflow: hidden;
    transition: ease-in-out 250ms;
    ul {
        display: grid;
        position: relative;
        li {
            margin: 1rem 2rem;
            font-size: 1.25rem;


        }
    }
    &.show {
        transform: translateY(120%);
        margin: 0px;
        top: 0px;
    }
    &.hide {
        transform: translateY(-300%);
    }
}

@include responsive(large) {
    nav {
        display: grid;
        align-items: center;
        height: 75px;
        background: black;
    }
  .mobile-nav {
      div {
          svg {
              display: none;
          }
          button { 
              color: grey;
              &.buyMe {
                display: grid;
                border: none;
                padding: .5rem 1rem;
                background: transparent;
              &:hover {
                  color: white;
              }
            }
        }
      }
  }
}